/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  background-color: #F5F5F5;
  font-size: 1rem;
  margin: 0;
  font-family: Roboto, sans-serif;
}

.container-fluid {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  padding-bottom: 10px;
  min-height: calc(100% - 25px);
  background-color: white;
  box-shadow: 0px 0px 5px 0px #c5c5c5;
}

/*------------------------
    Footer
-------------------------*/
.footer {
  position: relative;
  height: 50px;
  padding: 14px;
  background-color: #d3d7de;
}

  .footer p {
    font-size: 2vh;
    color: black;
  }
/*------------------------
    End Footer
-------------------------*/

/*------------------------
    Scroll bar
-------------------------*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #BBBCBC;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #BBBCBC;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(92, 136, 218)), color-stop(0.72, rgb(73, 125, 189)), color-stop(0.86, rgb(0 59 92)));
}
/*------------------------
    End Scroll bar
-------------------------*/

/*------------------------
    Slider
-------------------------*/

.slider-container {
  border-bottom: 20px solid #BBBCBC;
}

  .slider-container h1 {
    font-weight: bold;
    font-size: 6vh;
  }

  .slider-container h5 {
    font-size: 2.2vh;
    font-weight: 400;
  }

  .slider-container h6 {
    font-size: 2.2vh;
  }

  .slider-container p {
    font-size: 2.2vh;
    font-weight: 300;
    line-height: normal;
  }

.slider-cover-img {
  height: 95vh;
  object-fit: cover;
}

.slider-otr-leasing-img {
  height: 80px;
  width: 35vh !important;
  object-fit: contain;
  margin: auto;
  margin-bottom: 50px;
}
/*------------------------
    Slider
-------------------------*/


/*--------------------------
    Question circle
-------------------------*/
.questions {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

  .questions .question {
    margin: 2rem 3rem
  }

  .questions.minimum-magrin .question {
    margin: 2rem 1rem;
  }

@media (max-width: 575.98px) {
  .questions .question {
    margin: 1rem 2rem
  }
}

.questions .question .question-link {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  border: 0
}

.questions .question .question-circle {
  display: inline-block;
  justify-content: center;
  width: 7.75rem;
  height: 7.75rem;
  border: 7px solid #ff6720;
  border-radius: 100%
}

  .questions .question .question-circle.question-circle-blue {
    border-color: #003B5C;
  }

    .questions .question .question-circle.question-circle-blue .mat-icon {
      color: #003B5C;
    }

.questions .question:hover .question-circle.question-circle-blue {
  background: #003B5C;
}

.questions .question:hover .question-circle .mat-icon,
.questions .question:hover .question-circle .circle-inner-txt {
  color: white;
}

.question .question-circle .mat-icon {
  font-size: 3.5rem;
  font-weight: bold;
  height: auto !important;
  width: auto !important;
  color: #FF6720;
  margin-top: 27px;
}


.questions .question:hover {
  cursor: pointer
}

  .questions .question:hover .question-circle {
    background: #ff6720;
    color: #000
  }

.question .question-circle .circle-inner-txt {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 40px;
  letter-spacing: 2px;
}

@media (max-width: 575.98px) {
  .questions .question .question-circle {
    width: 7.75rem;
    height: 7.75rem;
    font-size: 3.5rem;
    margin: 0px 10px;
  }
}

.questions .question .question-answer {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
/*-------------------------
   End Question circle
-------------------------*/

/*------------------------
    Material design
-------------------------*/
.mat-icon {
  height: auto !important;
  width: auto !important;
}

.mat-mdc-form-field {
  display: flex !important;
}

.mat-mdc-outlined-button {
  border-radius: 5px !important;
  border: 3px solid #ff6720 !important;
  background-color: #ff6720 !important;
  font-size: 1.1rem !important;
  color: white !important;
  height: 55px !important;
  padding: 20px 35px !important;
}

  .mat-mdc-outlined-button.blue-btn {
    border: 2px solid #003b5c !important;
    color: #003b5c !important;
    background-color: white !important;
  }

.mat-mdc-card-actions .mdc-button {
  font-size: 0.8rem;
  padding: 0px 10px !important;
}

.mat-mdc-unelevated-button.mat-primary {
  background-color: #003B5C !important;
  border: 1px solid #003B5C !important;
}

.mat-mdc-unelevated-button.mat-accent {
  background-color: transparent !important;
  border: 1px solid #003B5C !important;
  color: #003B5C !important;
}

@media screen and (max-width: 1300px) {
  .mat-mdc-card-actions .mat-mdc-unelevated-button.mat-accent,
  .mat-mdc-card-actions .mat-mdc-unelevated-button.mat-primary {
    font-size: 0.7rem;
    padding: 0px 4px;
  }
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #4064d6 !important;
  --mdc-radio-selected-hover-icon-color: #4064d6 !important;
  --mdc-radio-selected-icon-color: #4064d6 !important;
  --mdc-radio-selected-pressed-icon-color: #4064d6 !important;
  --mat-radio-checked-ripple-color: #4064d6 !important;
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #4064d6 !important;
}

mat-label, input, .mat-mdc-select-min-line {
  font-size: 0.9rem !important;
}

mat-label {
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.required-lable-txt {
  color: #FF6720;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: white !important;
}

.mat-mdc-radio-button.mat-accent {
  margin: 0px 15px;
}

.mat-mdc-radio-button .mdc-form-field {
  font-size: inherit !important;
  font-weight: 500 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #003B5C !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #003B5C !important;
}

.social-link-title {
  font-size: 1rem;
  font-weight: 500;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ebf0f3 !important;
}

.mdc-text-field--filled {
  border-radius: 5px !important;
}

.mat-mdc-radio-group {
  line-height: 4;
}

.material-dialog-content {
  padding: 20px 30px;
}

.material-dialog-content p {
  color: black;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: #003B5C !important;
  padding: 0px 24px 20px 24px !important;
}

.mat-mdc-dialog-content {
  max-height: calc(100vh - 110px) !important;
}

.mat-mdc-dialog-container .mdc-dialog__content .col {
  margin-bottom: 10px;
}

.m-dialog-cancel-icon {
  font-size: 35px;
  position: absolute;
  right: 20px;
  top: 15px;
  height: auto !important;
  width: auto !important;
  cursor: pointer;
}

.dc-card img {
  height: 340px;
}

.mat-mdc-card p {
  font-weight: 500;
  color: black;
}

.c-miles {
  float: left;
}

.c-amt {
  text-align: right;
  color: #FF6720 !important;
}

.c-select-btn, .m-input-group-btn {
  margin-right: 10px;
}

.mdc-card__actions {
  padding: 15px !important;
}

.mat-button-toggle-group {
  border-radius: 40px !important;
  border: 3px solid #99b4cd;
}

.mat-button-toggle-button {
  color: white !important;
  background-color: #003B5C !important;
  padding: 0px 40px !important;
  border: 2px solid #003B5C !important;
  border-radius: 0px !important;
  font-size: 1.1rem !important;
}

.mat-mdc-outlined-button[disabled][disabled] {
  opacity: 0.5;
  /*
  border: 3px solid #c3c3c3 !important;
  color: #c3c3c3 !important;
  background-color: #f5f5f5;
  */
}

.mat-mdc-select-disabled, .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input
/*,.mat-mdc-radio-button .mdc-radio--disabled + label*/ {
  color: inherit !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: #ebf0f3 !important;
}

.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff6720 !important;
}

.custom-snackbar {
  margin-top: 40px !important;
  min-width: 400px;
  margin-right: 20px !important;
}

  .custom-snackbar .mdc-snackbar__surface {
    min-width: 500px !important;
    min-height: 85px;
    border-radius: 1px !important;
  }

  .custom-snackbar .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #FF6720 !important;
  }

  .custom-snackbar .mdc-snackbar__label {
    font-size: 1rem !important;
  }
.cs-success .mdc-snackbar__surface {
  background-color: #3c763d !important;
  /*border: 2px solid #416a81;*/
}
.cs-error .mdc-snackbar__surface {
  background-color: #a94442 !important;
  /*border: 2px solid #416a81;*/
}

.custom-snackbar .mat-icon {
  margin-right: 10px;
  float: left;
  margin-bottom: 22px;
  font-size: 2rem;
}

.custom-snackbar .snackbar-title {
  margin-bottom: 15px;
  margin-left: 40px;
  font-size: 1.4rem;
}
.custom-snackbar .snackbar-message {
  margin-top: 20px;
}

/*------------------------
    End Material design
-------------------------*/
/*------------------------
    Upload Document
-------------------------*/
.drop-container .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
/*------------------------
   End Upload Document
-------------------------*/

/*---------
    Table
-----------*/

.table-container {
  max-height: 405px; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add border */
  border-top: 2px solid #ff6720;
}
.custom-tbl {
  width: 100%; /* Full width table */
  border-collapse: collapse; /* Optional: Table styling */
  margin-bottom: 0;
}
.custom-tbl-title {
  font-size: 1rem;
  color: #003B5C;
  font-weight: 500;
}
.custom-tbl th {
  color: #003B5C !important;
  font-weight: 500;
  padding: 7px 10px !important;
  font-size: 0.9rem;
  position: sticky; /* Sticky positioning for the header */
  top: 0; /* Keep it stuck to the top */
  background-color: white; /* Background color to prevent overlap */
  z-index: 2; /* Ensure it stays on top of other elements */
  text-align: left; /* Optional: Align text to the left */
}

.custom-tbl td {
  color: #003B5C !important;
  padding: 7px 10px !important;
  font-size: 0.9rem;
}

/*---------
  End Table
-----------*/


.orange {
  color: #FF6720;
}

.light-orange {
  color: #ff6720c4;
}

.blue {
  color: #003B5C;
}

.light-blue {
  color: #5c88da;
}

.progress-container {
  height: 120px;
  background: #003B5C;
  border: 2px solid white;
  padding: 50px;
  position: relative;
}

.progress-truck-img-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 50px;
}

.progress-container .progress-truck-img {
  width: 120px;
  position: relative;
  left: 0;
  top: 30px;
  transition: width 0.6s ease;
}

.progress-container .progress {
  background-color: #416a81;
}

.progress-container .progress-bar {
  background-color: #FF6720;
  transition: none;
}

.progress-bar-row {
  position: relative;
  top: -140px;
}

.from-container-row {
  margin-top: -140px;
}

.form-container {
  background: white;
  padding: 40px 0px;
  color: #003B5C;
  /*min-height: 394px;*/
  width: 100%;
  /*box-shadow: 0px 0px 5px 0px #c5c5c5;*/
  position: relative;
}

  .form-container .col {
    margin-bottom: 20px;
  }

  .form-container h1 {
    font-size: 2rem;
    border-left: 5px solid #ff6720;
    padding-left: 25px;
    margin-bottom: 40px;
  }
    .form-container .form-title mat-icon {
      font-size: 2rem;
      position: relative;
      top: 4px;
    }
.sub-title-txt {
  font-size: 1.5rem;
}
.form-container .form-content {
  padding: 0px 70px;
}

.input-group .form-control {
  min-height: 55px;
}

.carousel-caption {
  top: calc(50% - 285px);
}

.carousel-indicators {
  margin-bottom: 9rem;
}

.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 59 92 / 78%);
}

.form-label {
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 5px;
}

.form-control {
  background-color: #ebf0f3;
  border: 1px solid #ebf0f3;
  border-radius: 3px;
  font-size: inherit;
}

.btn-primary {
  background-color: transparent;
  color: #FF6720;
  font-weight: 600;
  border: 2px solid #FF6720;
  border-radius: 0;
  border-bottom-left-radius: 15px;
}

.resend-again-txt {
  color: #FF6720;
  text-decoration: underline;
}

.input-group-btn {
  border-radius: 3px;
  background-color: #ff6720;
  color: white;
  border-color: #ff6720;
  padding: 5px 15px;
  min-height: 55px;
}

  .input-group-btn mat-icon {
    font-weight: bold;
    font-size: 25px;
    position: relative;
    top: 2px;
  }

.custom-link {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.how-many-truck-do-u-operate-container h1,
.app-submit-form-container h1,
.document-upload-form-container h1 {
  font-size: 2.9rem;
}

.min-width-400{
    min-width: 400px;
}

.text-right{
    text-align: right;
}

.t-registration-ul {
  padding-left: 15px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
}
  .t-registration-ul li {
    padding-bottom: 20px;
  }
.paragraph-tag {
  font-size: 0.9rem;
  font-weight: 500;
}

.sidebar-nav .mat-expansion-indicator::after {
  color: white !important;
  padding: 3.2px !important;
}
.sidebar-nav .mat-expansion-panel .mat-expansion-indicator {
  margin-right: 8px;
}

.sidebar-nav .mat-expansion-panel-body {
  padding: 6px 0px 0px !important;
}
.sidebar-nav .mat-expansion-panel-header:hover {
  background: #275f7fa1 !important;
}
.sidebar-nav .mat-expansion-panel-header.mat-expanded:focus, .sidebar-nav .mat-expansion-panel-header.mat-expanded:hover {
  background: #275f7fa1 !important;
}

.d-accordian-container .mat-expansion-panel {
  border-radius: 0px !important;
  box-shadow: 0px 3px 1px -4px rgba(0, 0, 0, 0.2), 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important;
  color: #003B5C !important;
}
.d-accordian-container .mat-expansion-panel-header.mat-expanded {
  border-top: 2px solid #ff6720;
  border-bottom: 2px solid #ff6720;
  height: 50px;
  border-radius: 0px;
}
  .d-accordian-container .mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title {
    color: #ff6720;
    font-weight: 600;
  }

.d-accordian-container .mat-expanded .mat-expansion-indicator::after {
  color: #ff6720;
}
.d-accordian-container .mat-expanded .mat-expansion-panel-body {
  padding-top: 20px;
  font-size: 1rem !important;
}
.d-accordian-container .mat-expansion-panel-header-title {
  font-size: 1rem;
  font-weight: 500;
  color: #003B5C;
}

.account-information-content .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff !important;
  border: 1px solid #195272b5;
}


.d-l-link {
  text-decoration: underline;
  cursor: pointer;
  color: cornflowerblue;
}

/*--------------------------
 Print Request Payoff Data
--------------------------*/
@media print {
  .cdk-overlay-pane {
    position: absolute !important;
    top: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
  }

  .container-mat-diaog-tempate button {
    visibility: hidden;
  }
}
/*------------------------------
 End Print Request Payoff Data
-------------------------------*/

/*------------------------
    Mobile responsive
-------------------------*/
@media only screen and (max-width: 768px) {
  .slider-otr-leasing-img {
    width: 30vh !important;
    margin-bottom: 0px;
  }

  .slider-container h1 {
    font-size: 3vh;
  }

  .carousel-caption {
    padding-top: 0;
    padding-bottom: 0;
    right: 8%;
    left: 8%;
  }

  .slider-container p {
    font-size: 1.7vh;
  }
  .form-container .form-content {
    padding: 0px 15px;
  }
  .form-container h1 {
    font-size: 1.5rem;
  }

  .questions .question {
    margin: 1rem 1rem !important;
  }
  .questions .question .question-circle {
    width: 5.75rem;
    height: 5.75rem;
    margin: 0px 0px;
  }

  .question .question-circle .mat-icon {
    font-size: 2.5rem;
    margin-top: 13px;
  }
  .question .question-circle .circle-inner-txt {
    margin-top: 25px;
  }
  .how-many-truck-do-u-operate-container .questions {
    display: block;
  }

  .progress-container .progress-truck-img {
    width: 80px;
    top: 41px;
  }

  .mat-mdc-snack-bar-handset .mdc-snackbar__surface {
    width: calc(100% - 40px) !important;
    margin-left: -41px;
    min-width: unset !important;
  }

  .mat-mdc-radio-button.mat-accent {
    margin: 0px 0px;
  }

  .stepper-wrapper {
    display: none !important;
  }
  mat-label {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

}

/*------------------------
   End Mobile responsive
-------------------------*/

.inactive-user {
  background: #c01717;
  color: white;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid gainsboro;
}
.customer-past-due {
  background: #c01717;
  color: white;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid gainsboro;
}
.customer-current-payment {
  background: #369f36;
  color: white;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid gainsboro;
}

.gm-container {
  width: 100%;
  height: 500px;
  border-radius: 5px;
}
